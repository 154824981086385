<template>
  <div class="flex gap-4 mt-5">
    <div class="atras flex align-middle">
      <i class="pi pi-angle-left text-blue-900 my-auto cursor-pointer"
         style="font-size: 1.5rem"
         @click="$router.go(-1)"
      />
    </div>
    <div class="text-[#354357] font-bold my-auto">
      <h2 class="text-lg">
        Personas
      </h2>
    </div>
    <div class="p-inputgroup">
    </div>
  </div>
  <div class="flex justify-center w-full mt-5">
    <div class="form-demo box py-8">
      <div class="flex w-full justify-center">
        <span class="text-lg font-semibold">Crear persona</span>
      </div>
      <div class="flex justify-center">
        <div class="card mb-9">
          <form @submit.prevent="send" class="p-fluid">
            <div class="field mb-3">
              <div class="p-float-label w-full">
                <InputText id="fullName" v-model="model.fullname"/>
                <label for="fullName">
                  Nombre completo
                </label>
                <MessageError :text="errors.fullname"/>
              </div>
            </div>
            <div class="field mb-3">
              <div class="p-float-label">
                <Dropdown id="name"
                          v-model="model.docTipoId"
                          :options="tiposDocumentos"
                          option-label="nombre"
                          option-value="code"
                          :filter="true"
                          style="min-width: 241px"
                />
                <label for="numeroIdentificacion">
                  Tipo Identificación
                </label>
                <MessageError :text="errors.numeroIdentificacion"/>
              </div>
            </div>
            <div class="field mb-3">
              <div class="p-float-label">
                <InputText id="numeroIdentificacion" v-model="model.numeroIdentificacion"/>
                <label for="numeroIdentificacion">
                  N° identificación
                </label>
                <MessageError :text="errors.numeroIdentificacion"/>
              </div>
            </div>
            <div class="field mb-3">
              <div class="p-float-label">
                <InputText id="telefono" v-model="model.telefono"/>
                <label for="telefono">
                  Numero telefono
                </label>
                <MessageError :text="errors.telefono"/>
              </div>
            </div>
            <div class="field mb-3">
              <div class="p-float-label">
                <InputText id="correo" v-model="model.correo"/>
                <label for="correo">
                  Correo electronico
                </label>
                <MessageError :text="errors.correo"/>
              </div>
            </div>
            <div class="field mb-3">
              <div class="p-float-label">
                <Dropdown v-model="model.departamentoId"
                          class="w-full"
                          :options="departamentos"
                          option-label="nomDpto"
                          option-value="codDpto"
                          @change="getMpio"
                          :filter="true"
                />
                <label>
                  Departamento
                </label>
                <MessageError :text="errors.departamentoId"/>
              </div>
            </div>
            <div class="field mb-3">
              <div class="p-float-label">
                <dropdown
                  class="w-full"
                  :options="municipios"
                  option-label="nomMpio"
                  option-value="codMpio"
                  v-model="model.municipioId"
                  :filter="true"
                />
                <label>
                  Municipio
                </label>
                <MessageError :text="errors.municipioId"/>
              </div>
            </div>
            <div class="flex justify-between gap-6">
              <Button label="Cancelar"
                      class="bg-blue-900 p-button-outlined"
                      @click="$router.go(-1)"
                      style="width: 12rem"
              />
              <Button label="Guardar"
                      type="submit"
                      class="bg-blue-900"
                      style="width: 12rem"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <Toast />
</template>

<script>
import { object, string } from 'yup'
import * as yup from 'yup'
import { useField, useForm } from 'vee-validate'
import { computed, onMounted, ref } from 'vue'
import storeLocalizacion from '../../../../store/localizacion.store'
import storePersona from '../../../../store/personaNatural.store'
import DocTipoService from '../../../../services/docTipo.service'
import { useToast } from 'primevue/usetoast'
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2'

export default {
  name: 'PersonasMainFormulario',
  setup () {
    const _DocTipoService = new DocTipoService()
    const toast = useToast()
    const router = useRouter()

    const tiposDocumentos = ref([])
    const validationSchema = object({
      fullname: string().nullable().required('El nombre completo es requerido').label('Nombre completo'),
      numeroIdentificacion: string().nullable().required('El N° de identificación es requerido').label('N° identificación'),
      telefono: string().nullable().required('El telefono es requerido').label('Numero de telefono'),
      correo: string().nullable().required('El correo es requerido').email().label('Correo electronico'),
      departamentoId: yup.string().required('El departamento es requerido').label(''),
      municipioId: yup.string().required('El municipio es requerido').nullable().label('')
    })

    const { errors, handleSubmit, values: model } = useForm({
      validationSchema
    })

    useField('fullname')
    useField('numeroIdentificacion')
    useField('telefono')
    useField('correo')
    useField('departamentoId')
    useField('municipioId')

    const send = handleSubmit((values) => {
      if (!values) return console.error('Error => validation')
      storePersona.dispatch('createPersona', values).then(({ data }) => {
        if (data.error) {
          toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'El documento ya existe', life: 3000 })
        } else {
          Swal.fire({
            title: 'Exito',
            text: 'Persona registrada con exito',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              router.go(-1)
            }
          })
        }
      })
    })

    const departamentos = computed(() => storeLocalizacion.getters._departamentos)
    const municipios = computed(() => storeLocalizacion.getters._municipios)

    const getMpio = ({ value }, municipioId = null) => {
      console.log(value)
      if (!value) {
        storeLocalizacion.commit('setMunicipios', [])
      } else {
        storeLocalizacion.dispatch('getMunicipios', value).then(() => {
          model.municipioId = municipioId
        })
      }
    }

    onMounted(async () => {
      await storeLocalizacion.dispatch('getDeparatamentos')
      _DocTipoService.getAll().then(({ data }) => {
        tiposDocumentos.value = data
      })
    })

    return {
      errors,
      model,
      send,
      departamentos,
      municipios,
      tiposDocumentos,
      getMpio
    }
  }
}
</script>

<style lang="scss" scoped>
.form-demo {
  width: fit-content;
  padding-left: 2rem;
  padding-right: 2rem;
  .card {
    min-width: 50%;
    padding-left: 10px;
    padding-right: 10px;

    form {
      margin-top: 2rem;
    }

    .field {
      margin-bottom: 1.5rem;
      width: 100%;
    }
  }

  @media screen and (max-width: 960px) {
    .card {
      width: 90%;
    }
  }
}
@media screen and (max-width: 960px) {
    .form-demo {
      padding-left: 0rem;
      padding-right: 0rem;
      width: 100%;
    }
  }

</style>
